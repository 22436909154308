import { Box, IconButton, Text, useDisclosure } from '@chakra-ui/react'
// import { PDF } from 'assets/icons'
import LoadingSpinner from 'components/LoadingSpinner'
import { useMedia } from 'data/media'
import React, { useEffect, useMemo, useState } from 'react'
import { Maximize2 } from 'react-feather'
import { NavLink, useHistory } from 'react-router-dom'
import formatDate from 'utils/formatDate'
import formatSize from 'utils/formatSize'

import styles from './SearchResultItem.module.css'
import { renderSearchSnippet } from './SearchResultItem'
import styled from 'styled-components'
import tw from 'twin.macro'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
// import { X } from 'assets/icons'
import Icon from '../IconLoader'

// const { VITE_REACT_APP_API_URL: API_URL } = import.meta.env
const { REACT_APP_API_URL: API_URL } = process.env

const getUrl = item => {
  return `/categories/${item.step?.process?.category?.id}/processes/${item.step?.process?.id}/${item.step?.id}`
}

const highlightSearchTerm = (text, searchTerm) => {
  if (!text) return

  const regex = new RegExp('(' + searchTerm + ')', 'gi')
  return text.replace(regex, '<mark>$1</mark>')
}

const SearchResultMediaItem = ({ item, searchTerm }) => {
  const [pdfSize, setPdfSize] = useState(null)
  const [uploadDate, setUploadDate] = useState(null)
  const [isLoadingIframe, setIsLoadingIframe] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const media = item

  const isPdf = media?.mime.includes('pdf')
  const isImage = media?.mime.includes('image')

  useEffect(() => {
    setIsLoadingIframe(true)
    if (media) {
      if (media.size) {
        setPdfSize(formatSize(media.size))
      }
      if (media.created_at) {
        setUploadDate(media.created_at)
      } else {
        setUploadDate(formatDate(new Date(), false))
      }
    }
  }, [media])

  const pdfModalContent = useMemo(() => {
    return (
      isOpen && (
        <MediaModal
          isOpen={isOpen}
          onClose={onClose}
          media={media}
          isLoadingIframe={isLoadingIframe}
          setIsLoadingIframe={setIsLoadingIframe}
        />
      )
    )
  }, [isOpen, onClose, media, isLoadingIframe, setIsLoadingIframe])

  // Return if is no media
  if (!media)
    return <div className="flex justify-center">Keine Medien gefunden.</div>

  return (
    <Box
      display="flex"
      w="full"
      p={4}
      mb={3}
      rounded="lg"
      shadow="base"
      bg="white"
      className={styles.searchResultItem}
    >
      <Box className={'relative mr-4 w-1/4 border border-gray-300 p-2'}>
        {isPdf ? (
          // <PDF className={'m-auto h-full w-1/2'} />
          <Icon name="pdf" />
        ) : isImage ? (
          <Image item={item} />
        ) : null}
        <IconButton
          aria-label="Open"
          icon={<Maximize2 />}
          onClick={onOpen}
          size="sm"
          // variant="ghost"
          position={'absolute'}
          right={2}
          top={2}
        />
      </Box>
      <Box className={'flex w-3/4 flex-col justify-between'}>
        <Box>
          <Text
            as="div"
            fontSize="sm"
            mt={0}
            mb={1}
            textColor="gray.400"
            isTruncated
            dangerouslySetInnerHTML={{
              __html: highlightSearchTerm(
                `${item.step?.process.category.name} / ${item.step?.process.name} / ${item.step?.title}`,
                searchTerm
              ),
            }}
          ></Text>
          <Box
            flex={1}
            as="h3"
            mb={1}
            fontSize="base"
            isTruncated
            dangerouslySetInnerHTML={{
              __html: renderSearchSnippet('name', item),
            }}
          />
          {pdfSize && uploadDate && (
            <div className="flex text-xs leading-none text-gray-500 mb-6">
              {uploadDate} | {pdfSize}
            </div>
          )}

          <Text
            as="p"
            fontSize="sm"
            dangerouslySetInnerHTML={{
              __html: renderSearchSnippet('content_index', item),
            }}
            className="text-wrap text-gray-600"
          />
        </Box>
        <Button as={NavLink} to={getUrl(item)} className={'ml-auto mt-4'}>
          Inhalt aufrufen
        </Button>
      </Box>
      {isOpen /* is necessary because the Howogeplateeditor and MediaModal have their own blocker that block each other  */ &&
        pdfModalContent}
    </Box>
  )
}


// import { unstable_useBlocker } from 'react-router-dom'

export function MediaModal({
  isOpen,
  onClose,
  media,
  isLoadingIframe,
  setIsLoadingIframe,
}) {
  // TODO: evtl in eine allgemeine Modal komponente auslagern und alle anderen Modals damit aufrufen
  // TODO: iwann gegen eine stabile verion austauschen
  // unstable_useBlocker(() => {
  //   if (isOpen) {
  //     onClose()
  //     return true
  //   }
  //   return false
  // })

  const history = useHistory()

  useEffect(() => {
    if (isOpen) {
      const unblock = history.block((tx) => {
        if (window.confirm('You have unsaved changes, do you really want to leave?')) {
          onClose()
          unblock()
          tx.retry()
        }
      })
      return () => {
        unblock()
      }
    }
  }, [isOpen, onClose, history])

  return (
    <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent style={{ margin: '0' }}>
        <ModalHeader style={{ padding: '0' }}>
          <div className={styles.modalHeader}>
            <div className={styles.modalHeaderContainer}>
              <div className="flex h-full w-full flex-col justify-center gap-1 px-4 ">
                <span className="text-sm font-semibold leading-tight text-slate-800 ">
                  {media.name}
                </span>
              </div>
              <div className="flex">
                <Button
                  rightIcon={<Icon name="x" />}
                  variant={'ghost'}
                  fontSize={'sm'}
                  fontWeight={'normal'}
                  onClick={onClose}
                >
                  Schließen
                </Button>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: '0', display: 'grid' }}>
          {isLoadingIframe && (
            <div className="flex h-full w-full items-center justify-center">
              <LoadingSpinner />
            </div>
          )}
          <iframe
            width="100%"
            height="100%"
            title={`${media.name}`}
            src={`${API_URL}${media.image_url}`}
            className={`${isLoadingIframe ? 'hidden' : ''}`}
            onLoad={() => setIsLoadingIframe(false)}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}



export const StyledImageWrapper = styled.div`
  ${tw`mb-2 flex flex-col items-center`}
`

const StyledContentImage = styled.div`
  ${tw`relative inline-block border border-gray-200`}
`

const StyledCaption = styled.p`
  ${tw`mt-2 mb-0 text-gray-600 text-center`}
`

const Image = ({ item }) => {
  const { caption } = {}
  const {
    media,
    isLoading: isMediaLoading,
    isError: isMediaError,
  } = useMedia(item?.id)

  if (!item) return

  if (isMediaLoading || isMediaError) return null
  // if (isMediaLoading || isMediaError)
  //   return <LoadingLayer isError={isMediaError} isLoading={isMediaLoading} />

  // Return if is no media
  if (!media) return null

  // Find large format and save as object in largeImage
  let largeImage = media.image_formats?.find(format => format.type === 'large')
  // Fallback for largeImage
  largeImage = largeImage ? largeImage : media

  return (
    <StyledImageWrapper>
      <StyledContentImage
        width={`${media.width}px`}
        height={`${media.height}px`}
      >
        <img
          className="w-full"
          src={`${API_URL}${largeImage.image_url}`}
          alt="Bild"
        />

      </StyledContentImage>
      {caption && <StyledCaption>{caption}</StyledCaption>}
    </StyledImageWrapper>
  )
}

export default SearchResultMediaItem
