// SearchResultFaqItem.jsx
import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { renderSearchSnippet } from './SearchResultItem'


const Heading = styled.h3`
  ${tw`text-lg font-bold`}

  mark {
    ${tw`bg-yellow-200`}
  }
`

const Paragraph = styled.p`
  ${tw`whitespace-pre-wrap text-base`}

  mark {
    ${tw`bg-yellow-200`}
  }
`

const SearchResultFaqItem = ({ faq }) => {

  return (
    <Box display="block" p={4} mb={3} rounded="lg" shadow="base" bg="white">
      <Heading
        dangerouslySetInnerHTML={{
          __html: renderSearchSnippet('question', faq, false),
        }}
      />
      <Paragraph
        dangerouslySetInnerHTML={{
          __html: renderSearchSnippet('question', faq, false),
        }}
      />
    </Box>
  )
}

SearchResultFaqItem.propTypes = {
  faq: PropTypes.shape({
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
}

export default SearchResultFaqItem
