import React, { Fragment, useCallback } from 'react'
import Headline from './Blocks/Headline'
import Image from './Blocks/Image'
import Layout from './Blocks/Layout'
import Link from './Blocks/Link'
import List from './Blocks/List'
import Paragraph from './Blocks/Paragraph'
import Table from './Blocks/Table'
import Video from './Blocks/Video'
import Pdf from './Blocks/Pdf'

import highlightText from 'utils/highlightText'

export const Renderer = ({ content, startIndex = 0, searchTerm = '' }) => {
  if (!content) {
    content = [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]
  }

  const renderElement = useCallback(
    (block = {}, index) => {
      const key = `${startIndex}-${index}`

      switch (block.type) {
        case 'p':
        case 'paragraph':
          return <Paragraph key={key} index={key} searchTerm={searchTerm} {...block} />
        case 'h1':
        case 'heading-one':
          return <Headline key={key} index={key} searchTerm={searchTerm} level={1} {...block} />
        case 'h2':
        case 'heading-two':
          return <Headline key={key} index={key} level={2} searchTerm={searchTerm} {...block} />
        case 'h3':
        case 'heading-three':
          return <Headline key={key} index={key} level={3} searchTerm={searchTerm} {...block} />
        case 'ol':
        case 'numbered-list':
          return <List key={key} index={key} type="ol" searchTerm={searchTerm} {...block} />
        case 'ul':
        case 'bulleted-list':
          return <List key={key} index={key} type="ul" searchTerm={searchTerm} {...block} />
        // each li element has it's content wrapped in lic items
        case 'lic':
        case 'li':
        case 'list-item':
          return (
            <Renderer content={block.children} startIndex={key} key={key} searchTerm={searchTerm} />
          )
        case 'link':
        case 'a':
          return <Link key={key} index={key} searchTerm={searchTerm} {...block} />
        case 'image':
          return <Image key={key} index={key} block={block} {...block} />
        case 'video':
          return <Video key={key} index={key} block={block} {...block} />
        case 'pdf':
          return <Pdf key={key} index={key} block={block} {...block} />
        case 'table':
          return <Table key={key} index={key} {...block} />
        case 'layout':
          return <Layout key={key} index={key} {...block} />
        default:
          return renderLeaf(block, key, searchTerm)
      }
    },
    [startIndex, searchTerm]
  )

  if (Array.isArray(content)) {
    return <>{content.map((block, index) => renderElement(block, index))}</>
  }

  return renderElement(content, startIndex)
}

const renderLeaf = (element, index, searchTerm) => {
  let { text } = element
  let output = searchTerm ? highlightText(text, searchTerm) : text

  if (element.children) {
    return (
      <Renderer content={element.children} startIndex={index} key={index} searchTerm={searchTerm} />
    )
  }

  text = searchTerm ? highlightText(text, searchTerm) : text

  if (element.bold) {
    output = <strong>{text}</strong>
  }

  if (element.code) {
    output = <code>{text}</code>
  }

  if (element.italic) {
    output = <em>{text}</em>
  }

  if (element.underline) {
    output = <u>{text}</u>
  }

  return <Fragment key={index}>{output}</Fragment>
}

export default Renderer
