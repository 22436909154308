import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CloseButton,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import axios from 'axios'

import ScrollWrapper from 'components/ScrollWrapper'
import Container from 'components/Container'

import LoadingSpinner from 'components/LoadingSpinner'
import SearchResultItem from 'components/Search/SearchResultItem'
import SearchResultMediaItem from 'components/Search/SearchResultMediaItem'
import { useCategories } from 'data/categories'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState, useContext } from 'react'

import { ChevronRight, Filter, HelpCircle, Search, X } from 'react-feather'
import { useLocation, /*useNavigate, */ useHistory } from 'react-router-dom'
import styles from 'scenes/Search.module.css'


import { SearchContext } from 'context/search-context'

import SearchResultFaqItem from '../components/Search/SearchResultFaqItem.jsx'
import SearchResultVideoItem
  from '../components/Search/SearchResultVideoItem.jsx'

const useQuery = () => queryString.parse(useLocation().search)

const SEARCHABLE_MODELS = [
  {
    name: 'process',
    label: 'Prozesse',
    // anzahl treffer in vorschau
    preview_count: 3
  },
  {
    name: 'step',
    label: 'Prozessschritte und Inhalte',
    preview_count: 10
  },
  {
    name: 'category',
    label: 'Kategorien',
    preview_count: 3
  },
  {
    name: 'topic',
    label: 'Themen',
    preview_count: 3
  },
  {
    name: 'media',
    label: 'Medien / Bilder',
    preview_count: 3
  },
  {
    name: 'video',
    label: 'Videos',
    preview_count: 3
  },
  {
    name: 'faq',
    label: 'FAQ',
    preview_count: 3
  }
]

const MEDIA_TYPES = [
  {
    name: 'image',
    label: 'Bilder',
    extensions: ['jpg', 'jpeg', 'png', 'gif']
  },
  {
    name: 'pdf',
    label: 'PDFs',
    extensions: ['pdf']
  },
  {
    name: 'powerpoint',
    label: 'Powerpoint',
    extensions: ['ppt', 'pptx']
  },
  {
    name: 'word',
    label: 'Word',
    extensions: ['doc', 'docx']
  },
  {
    name: 'excel',
    label: 'Excel',
    extensions: ['xls', 'xlsx']
  },
  {
    name: 'zip',
    label: 'ZIP',
    extensions: ['zip']
  },
  {
    name: 'xps',
    label: 'XPS',
    extensions: ['xps']
  }
]

const settingLabels = {
  models: 'Inhaltstypen',
  categories: 'Kategorien',
  mediaTypes: 'Medientypen'
}

const SearchScene = () => {
  const { searchParams, setSearchParams } = useContext(SearchContext)
  const queryParams = useQuery()
  // const navigate = useNavigate()
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState(queryParams.term || searchParams.term ||  '')
  const [page, setPage] = useState(parseInt(searchParams.page) || 1)
  const [tabIndex, setTabIndex] = useState(parseInt(queryParams.tabIndex) || parseInt(searchParams.tabIndex) || 0)
  const [searchResults, setSearchResults] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [hasSearched, sethasSearched] = useState(false)
  const [hasResults, setHasResults] = useState(false)
  // Add error state
  const [error, setError] = useState(null)

  const [settings, setSettings] = useState({
    models: [],
    categories: [],
    mediaTypes: []
  })
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  useEffect(() => {
    setHasResults(
      !isEmpty(searchResults) &&
      Object.values(searchResults).some(
        result => result.data && result.data.length > 0
      )
    )
  }, [searchResults])

  const performSearch = useCallback(
    async (query, customPage, customTabIndex) => {
      const newPage = customPage || page
      const newTabIndex = customTabIndex ?? tabIndex
      let isMounted = true

      if (query && query.trim().length !== 0) {
        setIsSearching(true)
        setError(null) // Reset error state

        try {
          const result = await axios.post(
            `/search`,
            { term: query },
            {
              params: {
                models: settings.models?.map(m => m.name),
                categories: settings.categories?.map(c => c.id),
                mediaTypes: settings.mediaTypes?.map(m => m.extensions).flat(),
                page: newPage,
                perPage: 10
              }
            }
          )

          if (isMounted) {
            setPage(newPage)
            setTabIndex(newTabIndex)
            setSearchTerm(query)
            setSearchResults(result?.data)
            setSearchParams({ term: query, page: newPage, tabIndex: newTabIndex }) // Update context

            const newQuery = { term: query ?? null, page: newPage }
            // navigate({ search: queryString.stringify(newQuery) })
            history.push({ search: queryString.stringify(newQuery) })
          }

        } catch (err) {
          if (isMounted) {
            setError(
              'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
            )
          }
        } finally {
          if (isMounted) {
            setIsSearching(false)
            setTimeout(() => sethasSearched(true), 100)
          }
        }
      } else {
        if(isMounted) {
          setSearchTerm('')
          setSearchResults(null)
        }
      }

      return () => {
        isMounted = false
      }
    },
    [settings, page, /*navigate, */history, setSearchParams]
  )

  useEffect(() => {
    sethasSearched(false)
  }, [searchTerm])

  useEffect(() => {
    if (searchTerm) performSearch(searchTerm, page, tabIndex)
    // only use on initial loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = useCallback(
    newPage => {
      performSearch(searchTerm, newPage)
    },
    [performSearch, searchTerm]
  )

  const handleRemoveSettings = useCallback(
    (item, type) => {
      const newSettings = settings[type]?.filter(s => s !== item)

      setSettings({ ...settings, [type]: newSettings })
    },
    [settings]
  )

  const onToggleSettingItem = useCallback(
    (type, item, checked) => {
      let newSettings = checked
        ? [...settings[type], item]
        : settings[type].filter(s => s !== item)

      let changedSettings = { ...settings, [type]: newSettings }

      // remove all media types if media is unchecked
      if (type === 'models' && item.name === 'media' && !checked) {
        changedSettings = {
          ...changedSettings,
          mediaTypes: []
        }
      }

      setSettings(changedSettings)
    },
    [settings]
  )

  // perform search when settings change
  useEffect(() => {
    if (searchTerm) performSearch(searchTerm)
  }, [settings, performSearch])

  const resetSettings = useCallback(() => {
    setSettings({
      models: [],
      categories: [],
      mediaTypes: []
    })
  }, [])

  const areSettingsActive =
    settings.models.length > 0 ||
    settings.categories.length > 0 ||
    settings.mediaTypes.length > 0

  return (
    <>
      <div className={'flex overflow-hidden h-screen'}>
        <ScrollWrapper className={'flex flex-col w-full h-full overflow-scroll'}>

          <Container>
            <div className='mb-6'>
              <h3>Suche</h3>
            </div>
            <div className='w-full flex-col items-center'>
              <div>
                <form
                  className='w-full'
                  onSubmit={e => {
                    e.preventDefault()
                    performSearch(searchTerm, 0, 0)
                  }}
                >
                  <Box className='flex items-center'>
                    <InputGroup size='md'>
                      <InputLeftElement height={'100%'}>
                        <IconButton
                          h='2rem'
                          icon={<Search size={20} />}
                          type='submit'
                          color='gray.300'
                          variant='ghost'
                          isLoading={isSearching}
                          _focus={{ outline: 'none' }}
                          _hover={{ bg: 'transparent' }}
                        />
                      </InputLeftElement>
                      <Input
                        autoFocus
                        type='text'
                        boxShadow='inner'
                        id='search'
                        placeholder='Suchbegriff eingeben…'
                        borderColor='gray.300'
                        bg='white'
                        _focus={{ outline: 'none' }}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        size='lg'
                      />
                      <InputRightElement width='4.5rem' height={'100%'} mr={2}>
                        <Button
                          type='submit'
                          colorScheme='blue'
                          size='md'
                          h='2rem'
                          m={0}
                          isLoading={isSearching}
                        >
                          Suche
                        </Button>
                      </InputRightElement>
                    </InputGroup>

                    <ButtonGroup
                      variant='outline'
                      size={'md'}
                      ml={4}
                      isAttached
                    >
                      <Button
                        aria-label='Filter'
                        isActive={isSettingsOpen}
                        onClick={() => {
                          setIsHelpOpen(false)
                          setIsSettingsOpen(settingsOpen => !settingsOpen)
                        }}
                        colorScheme='blue'
                        leftIcon={<Filter />}
                        borderRight={0}
                      >
                        Filter
                      </Button>
                      <IconButton
                        aria-label='Hilfe'
                        icon={<HelpCircle />}
                        onClick={() => {
                          setIsSettingsOpen(false)
                          setIsHelpOpen(helpOpen => !helpOpen)
                        }}
                        isActive={isHelpOpen}
                        colorScheme='blue'
                      />
                    </ButtonGroup>
                  </Box>
                </form>
              </div>

              {error && (
                <Alert status='error' mt={4}>
                  <AlertIcon />
                  <AlertTitle>Fehler</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                  <CloseButton
                    position='absolute'
                    right='8px'
                    top='8px'
                    onClick={() => setError(null)}
                  />
                </Alert>
              )}

              <div className={'mt-4 flex items-center'}>
                {Object.entries(settings).map(([name, value]) => (
                  <span className='inline-flex flex-wrap' key={name}>
                  {value.length > 0 && (
                    <span className='mr-2 mt-2 flex items-center text-sm'>
                      {settingLabels[name]}:{' '}
                    </span>
                  )}
                    {value.map((item, index) => (
                      <Button
                        key={index}
                        colorScheme='blue'
                        size='xs'
                        variant='outline'
                        rightIcon={<X className='-mr-1 w-4' />}
                        onClick={() => handleRemoveSettings(item, name)}
                        rounded={'md'}
                        className={'mr-2 mt-2 rounded-xl'}
                      >
                        {name === 'categories' ? item.name : item.label}
                      </Button>
                    ))}
                </span>
                ))}

                {areSettingsActive && (
                  <Button
                    colorScheme='blue'
                    variant={'link'}
                    size='xs'
                    onClick={resetSettings}
                    className={'ml-4 mt-2 p-2 underline'}
                  >
                    Alle Filter zurücksetzen
                  </Button>
                )}
              </div>

              {!isSearching && hasResults ? (
                <Tabs
                  index={tabIndex}
                  defaultIndex={tabIndex}
                  onChange={index => {
                    setTabIndex(index)
                    setSearchParams({ ...searchParams, tabIndex: index })
                    // history.push({ search: queryString.stringify({ ...queryParams, tabIndex: index }) })
                  }}
                  position='relative'
                  variant='unstyled'
                  className={'mt-10'}
                >
                  <TabList className={'border-b'}>
                    <Tab onClick={() => page !== 1 && handleChangePage(1)}>
                      Alles
                    </Tab>
                    {SEARCHABLE_MODELS.map((model, index) => (
                      <Tab
                        key={index}
                        className={
                          searchResults &&
                          searchResults[model.name]?.estimatedTotalHits > 0
                            ? ''
                            : 'opacity-50'
                        }
                        onClick={() => page !== 1 && handleChangePage(1)}
                      >
                        {model.label}
                        {searchResults &&
                          searchResults[model.name]?.estimatedTotalHits > 0 && (
                            <Badge
                              colorScheme='blue'
                              variant='solid'
                              className={'ml-2'}
                              rounded={'full'}
                            >
                              {searchResults[model.name]?.estimatedTotalHits}
                            </Badge>
                          )}
                      </Tab>
                    ))}
                  </TabList>
                  <TabIndicator
                    mt='-1.5px'
                    height='2px'
                    bg='blue.500'
                    borderRadius='1px'
                  />
                  <TabPanels>
                    <TabPanel px={0}>
                      {searchResults && (
                        <div>
                          {SEARCHABLE_MODELS.map(
                            (model, index) =>
                              searchResults[model.name]?.data &&
                              searchResults[model.name].data.length > 0 && (
                                <div key={index} className='my-8'>
                                  <h3 className='truncate'>
                                    {model.label}

                                    <small className={'ml-2 text-gray-400'}>
                                      {
                                        searchResults[model.name]
                                          .estimatedTotalHits
                                      }{' '}
                                      Ergebnisse
                                    </small>
                                  </h3>

                                  {model.name === 'faq' ? (
                                    <Accordion
                                      allowMultiple
                                      defaultIndex={Array.from(
                                        Array(
                                          searchResults[model.name].data.length
                                        ).keys()
                                      )}
                                    >
                                      {searchResults[model.name].data
                                        .slice(0, model.preview_count) // Limit results to preview_count
                                        .map((item, index) => (
                                          <SearchResultWrapperItem
                                            key={index}
                                            model={model}
                                            item={item}
                                            searchTerm={searchTerm}
                                            index={index}
                                          />
                                        ))}
                                    </Accordion>
                                  ) : (
                                    searchResults[model.name].data
                                      .slice(0, model.preview_count) // Limit results to preview_count
                                      .map((item, index) => (
                                        <SearchResultWrapperItem
                                          key={index}
                                          model={model}
                                          item={item}
                                          searchTerm={searchTerm}
                                          index={index}
                                        />
                                      ))
                                  )}

                                  <SearchResultItemMetaInfo
                                    model={model}
                                    displayedCount={Math.min(
                                      model.preview_count,
                                      searchResults[model.name].data.length
                                    )}
                                    totalHits={
                                      searchResults[model.name].estimatedTotalHits
                                    }
                                    onTabChange={setTabIndex}
                                  />
                                </div>
                              )
                          )}
                        </div>
                      )}
                    </TabPanel>

                    {SEARCHABLE_MODELS.map((model, index) => (
                      <TabPanel key={index} px={0}>
                        {searchResults && (
                          <div>
                            {searchResults[model.name]?.data &&
                              searchResults[model.name].data.length > 0 && (
                                <div className='my-8'>
                                  <div
                                    className={
                                      'mb-4 flex items-center justify-between'
                                    }
                                  >
                                    <h3 className='m-0 truncate'>
                                      {model.label}
                                    </h3>

                                    <Pagination
                                      currentPage={page}
                                      totalPages={
                                        searchResults[model.name].totalPages
                                      }
                                      onPageChange={newPage =>
                                        handleChangePage(newPage)
                                      }
                                      controls={false}
                                    />
                                  </div>
                                  {model.name === 'faq' ? (
                                    <Accordion
                                      allowMultiple
                                      defaultIndex={Array.from(
                                        Array(
                                          searchResults[model.name].data.length
                                        ).keys()
                                      )}
                                    >
                                      {searchResults[model.name].data.map(
                                        (item, index) => (
                                          <SearchResultWrapperItem
                                            key={index}
                                            model={model}
                                            item={item}
                                            searchTerm={searchTerm}
                                            index={index}
                                          />
                                        )
                                      )}
                                    </Accordion>
                                  ) : (
                                    searchResults[model.name].data.map(
                                      (item, index) => (
                                        <SearchResultWrapperItem
                                          key={index}
                                          model={model}
                                          item={item}
                                          searchTerm={searchTerm}
                                          index={index}
                                        />
                                      )
                                    )
                                  )}
                                  <Pagination
                                    currentPage={page}
                                    totalPages={
                                      searchResults[model.name].totalPages
                                    }
                                    onPageChange={newPage =>
                                      handleChangePage(newPage)
                                    }
                                    mt={4}
                                  />
                                </div>
                              )}
                          </div>
                        )}
                      </TabPanel>
                    ))}
                  </TabPanels>
                </Tabs>
              ) : searchTerm && isSearching ? (
                <div className='my-8'>Es wird gesucht…</div>
              ) : (
                hasSearched && (
                  <div className='my-8'>Keine Ergebnisse gefunden.</div>
                )
              )}
            </div>
          </Container>

        </ScrollWrapper>
        <SearchSettings
          isOpen={isSettingsOpen}
          settings={settings}
          onChange={onToggleSettingItem}
          onClose={() => setIsSettingsOpen(false)}
          resetSettings={resetSettings}
        />
        <SearchHelp isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
      </div>


    </>
  )
}

const SearchHelp = ({ isOpen, onClose }) => {
  return (
    isOpen && (
      <div className={styles.aside}>
        <div data-open={isOpen} className={styles.panel}>
          <Box className={styles.panelHeader} tabIndex={0}>
            <h3 className='m-0'>Suchoptionen und Hinweise</h3>
            <IconButton
              as='button'
              icon={<X size={20} />}
              colorScheme='gray'
              variant='ghost'
              size='sm'
              onClick={onClose}
            />
          </Box>
          <Box className={styles.panelBody}>
            <div className={'text-sm'}>
              <p>
                Hinweis: Bei der Suche werden Groß- Kleinschreibung, Akzente und
                diakritische Zeichen ignoriert.
              </p>
              <h3>Phrasensuche</h3>
              <p>
                Es besteht die Möglichkeit, eine Phrasensuche durchzuführen.
                Dabei werden die Begriffe mit{' '}
                <span
                  className={
                    'rounded-sm border border-gray-600 bg-gray-200 px-2 py-1 font-bold'
                  }
                >
                  "
                </span>{' '}
                umrandet.
                <span className={'my-1 block bg-gray-100 p-2'}>
                  Beispiel: <strong>„Übersichtlichkeit bei“</strong> liefert nur
                  Treffer mit genau dieser Wortfolge. Wobei die Suche nach{' '}
                  <strong>Übersichtlichkeit bei</strong> ohne{' '}
                  <span
                    className={
                      'rounded-sm border border-gray-600 bg-gray-200 px-2 py-1 font-bold'
                    }
                  >
                    "
                  </span>{' '}
                  auch Treffer mit den Wörtern{' '}
                  <strong>Übersichtlichkeit</strong> und/oder{' '}
                  <strong>bei</strong> liefert.
                </span>
                Bei der Phrasensuche werden weiche Trennzeichen wie{' '}
                <span
                  className={
                    'rounded-sm border border-gray-600 bg-gray-200 px-2 py-1 font-bold'
                  }
                >
                  -
                </span>
                ,
                <span
                  className={
                    'rounded-sm border border-gray-600 bg-gray-200  px-2 py-1 font-bold'
                  }
                >
                  ,
                </span>{' '}
                und{' '}
                <strong
                  className={
                    'rounded-sm border border-gray-600 bg-gray-200  px-2 py-1 font-bold'
                  }
                >
                  :
                </strong>{' '}
                werden ignoriert.
              </p>
              <p>
                Sie können die Phrasensuche und normale Suchanfragen in einer
                einzigen Suchanfrage kombinieren
              </p>
              <span className={'my-1 block bg-gray-100 p-2'}>
                Beispiel: <strong>„Max Mustermann“ Prozesse</strong> liefert
                Ergebnisse, die den Namen und optional Begriffe mit dem Wort{' '}
                <strong>Prozesse</strong> enthalten.
              </span>
            </div>
          </Box>
        </div>
      </div>
    )
  )
}

const SearchSettings = ({
                          settings,
                          onChange,
                          onClose,
                          isOpen,
                          resetSettings
                        }) => {
  const { categories, isLoading: isLoadingCategories } = useCategories()

  const areSettingsActive =
    settings.models.length > 0 ||
    settings.categories.length > 0 ||
    settings.mediaTypes.length > 0

  // group categories by topic
  const topics = categories?.reduce((acc, category) => {
    if (!acc[category.topic_id]) {
      acc[category.topic_id] = { ...category.topic, categories: [] }
    }

    acc[category.topic_id].categories.push(category)

    return acc
  }, [])

  return (
    isOpen && (
      <div className={styles.aside}>
        <div data-open={isOpen} className={styles.panel}>
          <Box className={styles.panelHeader} tabIndex={0}>
            <div>
              <h3 className='m-0'>Filter</h3>
              {areSettingsActive && (
                <Button
                  colorScheme='blue'
                  variant='link'
                  size='sm'
                  onClick={resetSettings}
                  className={'mt-2'}
                >
                  Alle Filter zurücksetzen
                </Button>
              )}
            </div>
            <IconButton
              as='button'
              icon={<X size={20} />}
              colorScheme='gray'
              variant='ghost'
              size='sm'
              onClick={onClose}
            />
          </Box>
          <Box className={styles.panelBody}>
            <Accordion border={0} defaultIndex={[0, 1]} allowMultiple>
              <AccordionItem border={'0px'}>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      Kategorie eingrenzen
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {isLoadingCategories ? (
                    <div className='flex items-center'>
                      <LoadingSpinner /> Lade Kategorien...
                    </div>
                  ) : null}

                  {topics?.map((t, t_index) => (
                    <div key={t_index}>
                      <div className={'mb-4'}>{t.name}</div>
                      <div>
                        {t.categories?.map((c, index) => (
                          <div key={index}>
                            <Checkbox
                              isChecked={settings.categories.includes(
                                categories[index]
                              )}
                              onChange={e =>
                                onChange(
                                  'categories',
                                  categories[index],
                                  e.target.checked
                                )
                              }
                            >
                              {categories[index].name}
                            </Checkbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem border={'0px'}>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      Welche Inhaltstypen sollen gesucht werden
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {SEARCHABLE_MODELS?.map((m, index) => (
                    <div key={index}>
                      <Checkbox
                        isChecked={settings.models.includes(m)}
                        onChange={e =>
                          onChange(
                            'models',
                            SEARCHABLE_MODELS[index],
                            e.target.checked
                          )
                        }
                      >
                        {m.label}
                      </Checkbox>
                      {m.name === 'media' && settings.models.includes(m) && (
                        <div className={'ml-2'}>
                          {MEDIA_TYPES.map((mediaType, index) => (
                            <div key={index}>
                              <Checkbox
                                isChecked={settings.mediaTypes.includes(
                                  mediaType
                                )}
                                onChange={e =>
                                  onChange(
                                    'mediaTypes',
                                    mediaType,
                                    e.target.checked
                                  )
                                }
                              >
                                {mediaType.label}
                              </Checkbox>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </div>
      </div>
    )
  )
}

const Pagination = ({
                      currentPage,
                      totalPages,
                      onPageChange,
                      controls = true,
                      ...rest
                    }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(parseInt(currentPage) - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(parseInt(currentPage) + 1)
    }
  }

  return (
    totalPages > 1 && (
      <HStack spacing={4} justify="center" {...rest}>
        {controls && (
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={handlePrevious}
            isDisabled={currentPage === 1}
          >
            Vorherige
          </Button>
        )}
        <Box>
          Seite {currentPage} von {totalPages}
        </Box>
        {controls && (
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={handleNext}
            isDisabled={currentPage === totalPages}
          >
            Nächste
          </Button>
        )}
      </HStack>
    )
  )
}

const SearchResultWrapperItem = ({ model, item, searchTerm, index }) => {
  if (model.name === 'faq') {
    // return <FAQItem key={index} index={index} faq={item} />
    return (
      <SearchResultFaqItem key={index} faq={item} searchTerm={searchTerm} />
    )
  } else if (model.name === 'media' /* && item.mime === 'application/pdf' */) {
    return (
      <SearchResultMediaItem key={index} item={item} searchTerm={searchTerm} />
    )
  } else if (model.name === 'video') {
    // return <Video key={index} video={item} />
    return (
      <SearchResultVideoItem key={index} video={item} searchTerm={searchTerm} />
    )
  } else {
    return (
      <SearchResultItem
        key={index}
        item={item}
        searchTerm={searchTerm}
        className={styles.searchResultItem}
      />
    )
  }
}

const SearchResultItemMetaInfo = ({
                                    model,
                                    displayedCount,
                                    totalHits,
                                    onTabChange
                                  }) => {
  if (totalHits <= displayedCount) {
    return null
  }

  return (
    <Box className='meta-info mt-4 flex items-center justify-end'>
      <span className={'text-sm text-gray-500'}>
        {displayedCount} von {totalHits} Ergebnisse
      </span>
      <Button
        colorScheme='blue'
        variant={'outline'}
        size='sm'
        rightIcon={<ChevronRight size={20} />}
        onClick={() => {
          onTabChange(
            SEARCHABLE_MODELS.findIndex(m => m.name === model.name) + 1
          )
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
        ml={2}
      >
        Alle anzeigen
      </Button>
    </Box>
  )
}

export default SearchScene
