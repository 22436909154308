import React from 'react'

const highlightText = (text, searchTerm) => {
  if (!searchTerm || !text) return text

  // Convert searchTerm to an array if it's not already
  const searchTermsArray = Array.isArray(searchTerm) ? searchTerm : searchTerm.split(/\s+/)
  const searchTerms = searchTermsArray.map(term => term.toLowerCase()) // Convert to lowercase
  const regex = new RegExp(`(${searchTerms.join('|')})`, 'gi') // Create regex for all words

  const parts = text.split(regex)
  return parts.map((part, index) =>
    searchTerms.includes(part.toLowerCase()) ? (
      <mark className={'bg-yellow-200'} key={index}>{part}</mark>
    ) : (
      part
    )
  )
}

export default highlightText