import React from 'react'
import Renderer from '../Renderer'

const Paragraph = ({ children, index, searchTerm }) => {
  return (
    <p>
      <Renderer content={children} startIndex={index} searchTerm={searchTerm} />
    </p>
  )
}

export default Paragraph
