// formated date example '11.12.2023 • 16.47'
const formatDate = (plainDate, withTime = true) => {
  if (!plainDate) return

  const date =
    plainDate instanceof Date ||
    Object.prototype.toString.call(plainDate) === '[object Date]'
      ? plainDate
      : new Date(plainDate)

  if (date.toString() === 'Invalid Date') return plainDate

  const dateArray = date
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(', ')

  return withTime ? dateArray.join(' • ') : dateArray[0]
}

export const formatDateGermanStyle = (date, withTime = true) => {
  // example 17. Okt. 2025, 12:14 Uhr
  const d = new Date(date)

  if (withTime) {
    return `${d.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })} Uhr`
  }

  return `${d.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })}`
}

export default formatDate
