import UnpublishedIcon from 'components/UnpublishedIcon'
import React from 'react'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

const TOC = styled.div`
  a {
    ${tw`flex items-center pr-2 py-2 rounded mb-px transition-all duration-100 ease-out`}

    &:hover {
      ${tw`bg-gray-200`}
    }

    &.active {
      ${tw`bg-gray-200`}
    }

    &:active {
      ${tw`bg-gray-300`}
    }

    & svg {
      ${tw`w-4 h-4`}
    }
  }
`

const StyledUnsortedList = styled.ul.withConfig({
  shouldForwardProp: prop => !['hasParent'].includes(prop),
})`
  ${tw`list-none pl-6`}

  ${props => props.hasParent && '--pseudo-content: "";'}

  li {
    &::before {
      content: var(--pseudo-content, none);
      left: -9px;
      ${tw`absolute top-0 w-0.5 bg-gray-200`}
    }

    &:last-of-type::before {
      ${tw`h-5`}
    }
    &:not(:last-of-type)::before {
      bottom: -0.25rem;
    }

    &::after {
      content: var(--pseudo-content, none);
      left: -9px;
      ${tw`absolute right-full top-5 h-0.5 bg-gray-200`}
    }
  }
`

const StyledStep = styled.li`
  ${tw`relative`}

  &:not(:last-child) {
    ${tw`mb-1`}
  }

  span {
    &,
    span {
      ${tw`w-full`}
    }

    span {
      hyphens: auto;
    }
  }
`

const StepHolder = ({ className, steps, baseUrl, isParentContext = false }) => {
  return (
    <StyledUnsortedList className={className} hasParent={isParentContext}>
      {steps &&
        steps.map(step => {
          return <Step key={step.id} step={step} baseUrl={baseUrl} />
        })}
    </StyledUnsortedList>
  )
}

const Step = ({ step, baseUrl, className }) => {
  // const [isCollapsed, setIsCollapsed] = useState(false)

  // const handleToggle = e => {
  //   e.preventDefault()
  //   setIsCollapsed(!isCollapsed)
  // }
  //
  // className for span:
  // ${step.children_sorted.length === 0 ? 'pl-2' : ''}`}
  //
  // button for collapse:
  // {step.children_sorted.length > 0 && (
  //   <button
  //     onClick={handleToggle}
  //     className="p-2 -my-2 focus:outline-none"
  //   >
  //     <ChevronDown
  //       size={16}
  //       className={`transform duration-100 transition-transform ${
  //         isCollapsed ? 'rotate-180' : ''
  //       }`}
  //     />
  //   </button>
  // )}

  return (
    <StyledStep className={className}>
      <NavLink className="justify-between" to={`${baseUrl}/${step.id}`}>
        <span className="flex items-center pl-2">
          <UnpublishedIcon entity={step} />
          <span>{step.title}</span>
        </span>
      </NavLink>

      {
        /* !isCollapsed &&*/ step.children_sorted.length > 0 && (
          <StepHolder
            steps={step.children_sorted}
            baseUrl={baseUrl}
            isParentContext={true}
          />
        )
      }
    </StyledStep>
  )
}

const ProcessTOC = ({ className, process, baseUrl }) => {
  const processUrlMatch = useRouteMatch(baseUrl).isExact

  return (
    <TOC className={className}>
      <div className="mb-2 pb-2 border-b sticky top-0 p-3 bg-gray-100 z-10">
        <Link
          className={`pl-2 mr-12 font-bold inline-flex items-center ${
            processUrlMatch ? 'active' : ''
          }`}
          to={baseUrl}
        >
          <UnpublishedIcon entity={process} />
          {process.name}
        </Link>
      </div>

      {process &&
        process.children_sorted &&
        process.children_sorted.length !== 0 && (
          <div className="mb-2 mx-3">
            <NavLink
              className="justify-between px-3 py-2"
              to={`${baseUrl}/${process.id}`}
            >
              <span className="w-full flex items-center">
                <UnpublishedIcon entity={process} /> {process.title}
              </span>
            </NavLink>
          </div>
        )}

      <StepHolder
        className="-ml-3 mr-3 mb-3"
        steps={process.steps}
        baseUrl={baseUrl}
      />
    </TOC>
  )
}

export default ProcessTOC
