import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'

import IconButton from 'components/Button/IconButton'
import CategoriesTOC from 'components/Category/CategoriesTOC'
import ProcessTOC from 'components/Process/ProcessTOC'

const StyledOffCanvasNav = styled.div`
  ${tw`fixed left-16 bottom-0 z-20 bg-gray-100`}

  flex: 0 0 ${({ naviWidth }) => naviWidth};
  width: ${({ naviWidth }) => naviWidth};

  transition: margin-left 0.5s, margin-right 0.5s, border-right 0.5s;
  margin-left: ${({ isOpen, naviWidth }) => (isOpen ? '0' : `-${naviWidth}`)};
  margin-right: ${({ isOpen }) => (isOpen ? '0' : `2rem`)};
`

const StyledMenuButton = styled(IconButton)`
  ${tw`p-2 rounded-full focus:outline-none`}
  ${({ isOpen }) => isOpen && tw`rounded`}
`

const StyledButtonWrapper = styled.div`
  ${tw`absolute right-0 mt-2 bg-gray-100 transition-all duration-500 ease-out p-1 rounded-r-full z-20 shadow -mr-12`}
  ${({ isOpen }) => isOpen && tw`mr-2 rounded-none border-0 shadow-none`}
`

const ResizeBar = styled.div`
  ${tw`absolute top-0 right-0 h-full w-10 transition-all duration-500 ease-out`}
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  cursor: ${({ isOpen }) => (isOpen ? 'ew-resize' : 'default')};
  border-right: ${({ isResizing }) => (isResizing && '0.12rem solid rgba(26,32,46,var(--bg-opacity))')};

  &:hover {
    display: block;
    ${tw`border-gray-300 border-r-2`}
  }
`

const OffCanvasNav = ({
                        isOpen,
                        categories,
                        process,
                        naviWidth,
                        onOpen,
                        onClose,
                        className = '',
                        currentStep = null,
                      }) => {
  let { url } = useRouteMatch()
  const [width, setWidth] = useState(naviWidth)
  const [isResizing, setIsResizing] = useState(false)
  const navRef = useRef(null)
  const initialWidthRef = useRef(null)

  useEffect(() => {
    if (navRef.current) {
      const computedStyle = getComputedStyle(navRef.current)
      initialWidthRef.current = parseFloat(computedStyle.width)
    }
  }, [naviWidth])

  const handleMouseDown = e => {
    if (isOpen && e.target.classList.contains('resize-bar')) {
      setIsResizing(true)
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }
  }

  const handleMouseMove = e => {
    const newWidth = e.clientX
    if (newWidth >= initialWidthRef.current) {
      setWidth(`${newWidth}px`)
    }
  }

  const handleMouseUp = () => {
    setIsResizing(false)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  return (
    <StyledOffCanvasNav
      className={className}
      isOpen={isOpen}
      naviWidth={width}
      onMouseDown={handleMouseDown}
      ref={navRef}
    >
      <StyledButtonWrapper isOpen={isOpen}>
        <StyledMenuButton
          icon={isOpen ? 'x' : 'menu'}
          className="p-2 rounded-full focus:outline-none"
          isOpen={isOpen}
          onClick={() => {
            isOpen ? onClose() : onOpen()
          }}
        />
      </StyledButtonWrapper>
      <div className="relative h-full overflow-y-auto">
        {process && (
          <ProcessTOC
            className="ProcessTOC"
            process={process}
            baseUrl={url}
            currentStep={currentStep}
          />
        )}
        {categories && <CategoriesTOC categories={categories} />}
      </div>
      <ResizeBar className="resize-bar" isOpen={isOpen} isResizing={isResizing} />
    </StyledOffCanvasNav>
  )
}

OffCanvasNav.propTypes = {
  isOpen: PropTypes.bool,
  toggleShowNavigation: PropTypes.func,
}

export default OffCanvasNav