const formatSize = (bytes) => {
  let converted = ''
  if (bytes >= 1099511627776) {
    converted = `${(bytes / 1099511627776).toFixed(2)} TB`
  } else if (bytes >= 1073741824) {
    converted = `${(bytes / 1073741824).toFixed(2)} GB`
  } else if (bytes >= 1048576) {
    converted = `${(bytes / 1048576).toFixed(2)} MB`
  } else if (bytes >= 1024) {
    converted = `${(bytes / 1024).toFixed(2)} KB`
  } else {
    converted = `${bytes} bytes`
  }

  return converted
}

export default formatSize
