import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Icon from 'components/IconLoader'
import React, { useMemo } from 'react'
import { Maximize2, PlayCircle, X } from 'react-feather'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import tw from 'twin.macro'

import { renderSearchSnippet } from './SearchResultItem.jsx'
import styles from './SearchResultItem.module.css'

// const { VITE_REACT_APP_API_URL: API_URL } = import.meta.env
const { REACT_APP_API_URL: API_URL } = process.env

const highlightSearchTerm = (text, searchTerm) => {
  if (!text) return
  const regex = new RegExp('(' + searchTerm + ')', 'gi')
  return text.replace(regex, '<mark>$1</mark>')
}

const SearchResultVideoItem = ({ video, searchTerm }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const videoModalContent = useMemo(() => {
    return (
      isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
          <ModalOverlay />
          <ModalContent style={{ margin: '0' }}>
            <ModalHeader style={{ padding: '0' }}>
              <div className={styles.modalHeader}>
                <div className={styles.modalHeaderContainer}>
                  <div className="flex h-full w-full flex-col justify-center gap-1 px-4 ">
                    <span className="text-sm font-semibold leading-tight text-slate-800 ">
                      {video.name}
                    </span>
                  </div>
                  <div className="flex">
                    <Button
                      rightIcon={<X />}
                      variant={'ghost'}
                      fontSize={'sm'}
                      fontWeight={'normal'}
                      onClick={onClose}
                    >
                      Schließen
                    </Button>
                  </div>
                </div>
              </div>
            </ModalHeader>
            <ModalBody style={{ padding: '0', display: 'grid' }}>
              <Video video={video} categoryName={video.category?.name} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )
    )
  }, [isOpen, onClose, video])

  const videoDetails = video.data?.streams?.find(
    stream => stream.codec_type === 'video'
  )
  const format = videoDetails.codec_name
  const size = (video.data.format.size / (1024 * 1024)).toFixed(2) + ' MB'
  const duration = new Date(videoDetails.duration * 1000)
    .toISOString()
    .substr(11, 8)

  return (
    <Box
      display="flex"
      w="full"
      p={4}
      mb={3}
      rounded="lg"
      shadow="base"
      bg="white"
      className={styles.searchResultItem}
    >
      <Box className={'relative mr-4 w-1/4'} onClick={onOpen}>
        <Video video={video} controls={false} />
        <IconButton
          aria-label="Open"
          icon={<Maximize2 />}
          onClick={onOpen}
          size="sm"
          position={'absolute'}
          right={2}
          top={2}
        />
      </Box>
      <Box className={'flex w-3/4 flex-col justify-between'}>
        <Box>
          <Text
            as="div"
            fontSize="sm"
            mt={0}
            mb={1}
            textColor="gray.400"
            isTruncated
            dangerouslySetInnerHTML={{
              __html: highlightSearchTerm(video.category?.name, searchTerm),
            }}
          />
          <Text
            as="h3"
            fontSize="base"
            isTruncated
            dangerouslySetInnerHTML={{
              __html: renderSearchSnippet('name', video),
            }}
          />
          <Text fontSize="sm" color="gray.500">
            Format: {format} | Größe: {size} | Dauer: {duration}
          </Text>
        </Box>
        <Button
          onClick={onOpen}
          className={'ml-auto mt-4'}
          rightIcon={<Maximize2 />}
        >
          Video anzeigen
        </Button>
      </Box>
      {isOpen && videoModalContent}
    </Box>
  )
}

const VideoWrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['isHidden'].includes(prop),
})`
  ${tw`relative w-full overflow-hidden no-underline transition-shadow duration-200 ease-out bg-white border rounded-lg border-gray-200 hover:shadow-lg`}
  ${props => props.isHidden && tw`border-gray-500`}
`

const StyledReactPlayer = styled(ReactPlayer).withConfig({
  shouldForwardProp: prop => !['isHidden'].includes(prop),
})`
  ${tw`relative`}
  ${props => props.isHidden && tw`opacity-25`}

  & > div,
  & > video {
    ${tw`absolute inset-0 h-full`}
  }

  & svg {
    ${tw`h-auto w-1/5 bg-white bg-opacity-50 rounded-full transition-colors duration-100`}
  }

  &:hover,
  &:focus {
    & svg {
      ${tw`bg-opacity-75`}
    }
  }

  &:active {
    & svg {
      ${tw`bg-opacity-100`}
    }
  }
`

const Video = ({ video, controls = true }) => {
  const isHidden = Boolean(video.category?.hidden)

  return (
    <VideoWrapper isHidden={isHidden}>
      <div className="relative">
        <StyledReactPlayer
          isHidden={isHidden}
          style={{ paddingTop: '56.25%' }}
          width="100%"
          height="auto"
          url={`${API_URL}${video.video_url}`}
          light={`${API_URL}${video.thumb_url}`}
          controls={controls}
          muted
          pip={false}
          playIcon={<PlayCircle size={100} strokeWidth={0.5} />}
        />
        {isHidden && (
          <div className="absolute right-4 top-4 flex items-center">
            <span className="mr-2">Video in Ausgeblendeter Kategorie</span>
            <Icon
              className="h-6 w-6 flex-shrink-0 text-gray-500"
              name="alert-circle"
            />
          </div>
        )}
      </div>
    </VideoWrapper>
  )
}

export default SearchResultVideoItem
