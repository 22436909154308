import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { SearchContext } from 'context/search-context'

const getType = item => item.__type

const getUrl = item => {
  switch (getType(item)) {
    case 'Category':
      return `/categories/${item.id}`
    case 'Process':
      return `/categories/${item.category.id}/processes/${item.id}`
    case 'Step':
      return `/categories/${item.category.id}/processes/${item.process.id}/${item.id}`
    default:
      return null
  }
}

const getPath = item => {
  switch (getType(item)) {
    case 'Category':
      return `Themen und Kategorien / ${item.topic.name} / ${item.name}`
    case 'Process':
      return `Themen und Kategorien / ${item.topic.name} / ${item.category.name} / ${item.name}`
    case 'Step':
      return `Themen und Kategorien / ${item.topic.name} / ${item.category.name} / ${item.process.name} / ${item.title}`
    default:
      return null
  }
}

export const renderSearchSnippet = (key, item, shouldDotOut = true) => {
  let htmlString =
    item.__search_snippets[key] && item.__search_snippets[key].length > 0
      ? item.__search_snippets[key]
          .map(
            snippet => `<span>${snippet} ${shouldDotOut ? '...' : ''}</span>`
          )
          .join(' ')
      : null

  if (!htmlString) {
    htmlString = item[key]
    // truncate the string to 200 characters
    if (htmlString && htmlString.length > 200) {
      htmlString = htmlString.substring(0, 200) + '...'
    }
  }

  return htmlString
}

// findet alle Begriffe die mit dem em tag markiert sind aus den Suchergebnissen (__search_snippets[key]) und gibt sie als Array zurück
const getResultTerms = (item, keys) => {
  let result = []
  keys.forEach(key => {
    if (item.__search_snippets[key]) {
      item.__search_snippets[key].forEach(snippet => {

        // findet alle Begriffe die mit dem em tag markiert sind
        const terms = snippet.match(/<mark>(.*?)<\/mark>/g)
        if (terms) {
          terms.forEach(term => {
            result.push(term.replace(/<\/?mark>/g, ''))
          })
        }
      })
    }
  }
  )
  return result
}

const SearchResultItem = ({ item, className }) => {
  const { searchParams, setSearchParams } = useContext(SearchContext)

  const handleClick = () => {
    const terms = getResultTerms(item, ['title', 'content_index', 'description'])
    setSearchParams({ ...searchParams, terms: terms })
  }

  return (
    <Box
      as={NavLink}
      to={{
        pathname: getUrl(item),
        state: { from: '/search' } // Set the state property here
      }}
      display="block"
      p={4}
      mb={3}
      rounded="lg"
      shadow="base"
      bg="white"
      className={className}
      onClick={handleClick}
    >
      <Flex align="start" justify="space-between">
        <Box flex={1} w="full">
          <Text
            as="div"
            fontSize="sm"
            mt={0}
            mb={1}
            textColor="gray.400"
            isTruncated
          >
            {getPath(item)}
          </Text>
          <Box>
            <Text
              flex={1}
              as="h3"
              fontSize="base"
              isTruncated
              dangerouslySetInnerHTML={{
                __html: renderSearchSnippet(
                  ['Step'].includes(getType(item)) ? 'title' : 'name',
                  item,
                  false
                ),
              }}
            />
            {getType(item) === 'Step' ? (
              <Text
                as="p"
                fontSize="sm"
                dangerouslySetInnerHTML={{
                  __html: renderSearchSnippet('content_index', item),
                }}
                className="text-wrap text-gray-600"
              />
            ) : ['Process', 'Category'].includes(getType(item)) ? (
              <Text
                as="p"
                fontSize="sm"
                dangerouslySetInnerHTML={{
                  __html: renderSearchSnippet('description', item),
                }}
                className="text-wrap text-gray-600"
              />
            ) : null}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default SearchResultItem
