import React from 'react'
import Renderer from '../Renderer'

const Headline = ({ level = 2, children = [], index = 0 , searchTerm}) => {
  let HeadlineType = level ? `h${level}` : 'h2'

  return (
    <HeadlineType>
      <Renderer content={children} startIndex={index} searchTerm={searchTerm} />
    </HeadlineType>
  )
}

export default Headline
