import axios from 'axios'

export const fetchOpenGraphData = ({
  url,
  allMeta,
  lang,
  options,
  userAgent,
  signal,  // Das neue AbortSignal
}) => {
  if (!url) throw new Error('url is required')

  const axiosOptions = {
    signal: signal ? signal : undefined,
  }

  return axios.post('/open-graph-data', {
    data: { url, allMeta, lang, options, userAgent },
    axiosOptions,
  })
}

export const getFavicon = url => {
  if (!url) throw new Error('url is required')

  return `https://www.google.com/s2/favicons?domain=${url}`
}
