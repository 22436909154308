import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useLocation } from 'react-router-dom'

import { useStep } from 'data/steps'

import Container from 'components/Container'
import LoadingLayer from 'components/LoadingLayer'
import ProcessStepRenderer from 'components/ProcessStepRenderer'
import { LinkedReference } from 'components/Steps/LinkedReference'
import UnpublishedIcon from 'components/UnpublishedIcon'
import { useDate } from 'hooks/use-format-date'
import {
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react'
import auth from 'services/AuthService'
import { nl2br } from 'utils/nl2br'

import { SearchContext } from 'context/search-context'
import highlightText from '../../../utils/highlightText'

const StyledStepContentWrapper = styled.div`
  ${tw`flex-col flex-grow flex-shrink-0 bg-white whitespace-pre-wrap`}

  & img {
    ${tw`max-w-full`}
  }

  & p {
    ${tw`mb-2 min-h-4`}
  }

  & ol {
    ${tw`pl-4 mt-2 mb-4 list-decimal`}
  }

  & ul {
    ${tw`pl-4 mt-2 mb-4 list-disc`}

    & ul {
      ${tw`my-0`}
    }
  }
`

const ShowProcessStep = ({ setStepInBreadcrumb }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const user = auth.getAuth()
  const isPreview =
    queryParams.get('preview') === 'true' &&
    (user?.is_supervisor || user?.is_publisher || user?.is_editor)

  const { sid } = useParams()
  const { step, isLoading: isStepLoading, isError: isStepError } = useStep(sid)

  const { searchParams } = useContext(SearchContext)

  const isPublished = Boolean(step?.published_at)
  const isDeactivated = Boolean(step?.deactivated_at)
  const deactivatedMessage = (
    <Alert status="warning" rounded="md" className="mt-4" textAlign="left">
      <AlertIcon />
      Dieser Beitrag ist aus folgendem Grund deaktiviert:
      <br />
      {nl2br(step?.deactivated_message)}
    </Alert>
  )

  const hasPreview =
    isPreview && (Boolean(step?.modified_content) || isDeactivated)
  const canSwitchBetweenStates = hasPreview && isPublished

  if (!localStorage.getItem('showEditState')) {
    localStorage.setItem('showEditState', hasPreview)
  }

  const [showEditState, setShowEditState] = useState(
    localStorage.getItem('showEditState') === 'true'
  )
  const showEditStateWithPublish = hasPreview && (showEditState || !isPublished)
  const [content, setContent] = useState(
    isPublished && step?.content
      ? JSON.parse(step?.modified_content || step?.content)
      : step?.modified_content
      ? JSON.parse(step?.modified_content)
      : undefined
  )
  const [title, setTitle] = useState(step?.title)

  useEffect(() => {
    if (!step) return

    if (isDeactivated && showEditStateWithPublish) {
      setContent(JSON.parse(step.modified_content || step.content))
      setTitle(step.modified_title || step.title)
    } else if (showEditStateWithPublish) {
      setContent(JSON.parse(step.modified_content))
      setTitle(step.modified_title)
    } else {
      setContent(JSON.parse(step.content))
      setTitle(step.title)
    }
  }, [step, showEditStateWithPublish])

  const updateStepInBreadcrumb = useCallback(
    step => {
      setStepInBreadcrumb(step)
    },
    [setStepInBreadcrumb]
  )

  const date = useDate(
    isDeactivated
      ? step?.deactivated_at
      : step?.verified_at || step?.published_at,
    false
  )

  const verified_at = useDate(step?.verified_at, false)
  const deactivated_at = useDate(step?.deactivated_at, false)
  const published_at = useDate(step?.published_at, false)

  // Setze aktuellen Step für Breadcrumb
  useEffect(() => {
    if (!isStepLoading && !isStepError && step) {
      updateStepInBreadcrumb(step)
    }
  }, [isStepLoading, isStepError, step, updateStepInBreadcrumb])

  if (isStepLoading || isStepError)
    return <LoadingLayer isError={isStepError} isLoading={isStepLoading} />


  // Überprüfen, ob die vorherige Route die Suchroute war
  const previousRoute = location.state?.from
  const searchTerm = previousRoute === '/search' ? searchParams.terms || searchParams.term : ''

  return (
    <Container className="bg-white">
      {canSwitchBetweenStates ? (
        <FormControl
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <FormLabel htmlFor="preview-switch">
            Unveröffentlichten Zustand ansehen?
          </FormLabel>
          <Switch
            id="preview-switch"
            isChecked={showEditState}
            onChange={({ target }) => {
              setShowEditState(target.checked)
              localStorage.setItem('showEditState', target.checked)
            }}
          />
        </FormControl>
      ) : hasPreview ? (
        <div className="flex justify-end">
          <span className="text-gray-500">Vorschau</span>
        </div>
      ) : null}

      <div className='flex items-start w-full justify-between'>
        <div className='flex items-baseline gap-x-2'>
          <UnpublishedIcon entity={step} />
          <h1 className=' '>
            {highlightText(title, searchTerm)}
          </h1>
        </div>

        {!showEditStateWithPublish && date && (
          <div className={'inline-flex flex-col'}>
            <span className='text-gray-500'>
              {isDeactivated ? `Deaktiviert: ${deactivated_at}` : `Veröffentlicht: ${published_at}`}
            </span>
            {step?.verified_at && !isDeactivated && (
              <span className='text-gray-500'>
                Aktualisiert: {verified_at}
              </span>
            )}
          </div>
        )}
      </div>
      <LinkedReference step={step} showEditState={showEditStateWithPublish} />
      <StyledStepContentWrapper>
        {isDeactivated && !showEditStateWithPublish ? (
          deactivatedMessage
        ) : (
          <ProcessStepRenderer content={content} searchTerm={searchTerm} />
        )}
      </StyledStepContentWrapper>
    </Container>
  )
}

export default ShowProcessStep
