import React from 'react'
import Renderer from '../Renderer'

const List = ({ children = [], type: ListType = 'ul', index = 0, searchTerm}) => {
  return (
    <ListType>
      {Array.isArray(children) &&
        children.map((child, i) => {
          const key = `${index}-${i}`
          return (
            <li key={key}>
              <Renderer content={child} startIndex={key} searchTerm={searchTerm} />
            </li>
          )
        })}
    </ListType>
  )
}

export default List
